const OnloadHandler = function(onload_cb, before_visit_cb){
  var isTurbolinks = 'Turbolinks' in window && window.Turbolinks.supported;

  if (isTurbolinks) {
    document.addEventListener('turbolinks:load', function () {
      if (typeof onload_cb === "function") {
        onload_cb();
      }
    });
    document.addEventListener('turbolinks:before-visit', function () {
      if (typeof before_visit_cb === "function") {
        before_visit_cb();
      }
    });
  } else {
    document.addEventListener('DOMContentLoaded', function () {
      if (typeof onload_cb === "function") {
        onload_cb();
      }
    });
  }

}

export default OnloadHandler