import OnloadHandler from '../packs/onload_handler';

OnloadHandler(function () {
    $("[data-upload-file-form]").change(function() {
        const MAX_LIMIT_FOR_FILE = 5242880
        let sizeInMB = (MAX_LIMIT_FOR_FILE / (1024*1024)).toFixed(0);
        sizeInMB = sizeInMB + ' MB'
        if(this.files[0].size > MAX_LIMIT_FOR_FILE){
                $('[data-upload-file-form]').val('');
                alert('Solo file con una dimensione totale fino a ' + sizeInMB  + ' sono accettati')
            }
        });
});