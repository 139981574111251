import OnloadHandler from '../packs/onload_handler';

OnloadHandler(function () {
    $("[data-recaptcha-form]").click(
        function (e) {
            e.preventDefault();
            const form = e.currentTarget.closest("form");
            const hidden_input = form.querySelector(".g-recaptcha-response");
            const recaptcha_site_key = hidden_input.dataset['sitekey'];
            const recaptcha_action = e.currentTarget.dataset['recaptchaForm'];
            grecaptcha.execute(recaptcha_site_key, {action: recaptcha_action}).then(function (token) {
                    hidden_input.value = token
                    $.rails.refreshCSRFTokens();
                    $.rails.fire(form, 'submit')
                }
            )
        });
});


